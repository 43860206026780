import {IFace } from '../@types/Face';
import { useEffect,useState } from 'react';

type IProps = {
  face: IFace;
  imgWidth: string,
  imgHeight: string
};

const FaceComponent: React.FC<IProps> = ( props:IProps) => {

  const [currentThumbnailUrl, setThumbnailUrl] = useState('');
  const [lastSeenstr, seLastSeenstr] = useState('');
  const [genderICO, setGenderICO] = useState('');

  useEffect(()=>{
    seLastSeenstr(date_TO_String(new Date(props.face.lastSeen)));
    setThumbnailUrl(props.face.thumbnailUrl);
    setGenderICO('images/' + props.face.gender + '.png');
}, []); // empty array means only once

  const handleImageError = () => {
    setThumbnailUrl('images/unknown.gif');
   }  

   function date_TO_String(date_Object: Date): string {
    // get the year, month, date, hours, and minutes seprately and append to the string.
    let date_String: string =
      date_Object.getDate() +
      "/" +
      (date_Object.getMonth() + 1) +
      "/" +
      +date_Object.getFullYear();

    return date_String;
  }

  function openInNewTab(url:string){
    window.open(url, "_blank", "noreferrer");
  };

return (
  
    <div className="results">
      <div>
            <div className='thumb'>
              <div className='thumbInner'>
                <img  onError={handleImageError} src={`${currentThumbnailUrl}`}  width={props.imgWidth} height={props.imgHeight}/>
              </div>     
            </div>
            <div>              
              <span>
                &nbsp;&nbsp;<b id="match_name">{props.face.name}</b>&nbsp;
                &nbsp;
              </span><br/>
              <span>Certainty:&nbsp;{Number(props.face.score) > 0 && (   
                props.face.score + '%'
                )}</span>
            </div>  
    </div>
  </div>
  );
}
export default FaceComponent;