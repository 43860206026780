import Faces  from '../@containers/Faces';
import SearchComponent from './Search.Component';
import { Routes, Route } from 'react-router-dom';

const Main = () => {
    return ( 
        <Routes>
        <Route path='/' element={<Faces/>} />
        <Route path='/model/:site/:model' element={<SearchComponent/>} />
      </Routes>        
    );
 }
export default Main;