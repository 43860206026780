import axios from "axios";
const https = require('https');

const selfCerthttpsAgent = new https.Agent({
  rejectUnauthorized: false
});

export default class UploadService {
 
    private static instance: UploadService;
  
    baseUrl: string;
    private constructor() { 
  
      const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
      this.baseUrl = baseUrl! + '/image';
    }
  
    public static getInstance(): UploadService {
      if (!UploadService.instance) {
        UploadService.instance = new UploadService();
      }
      return UploadService.instance;
    }


 uploadFile = (file: any, onUploadProgress: any): Promise<any> => {
  let formData = new FormData();

  formData.append("image", file);

  return axios.post(this.baseUrl + '/upload', formData, {
    httpsAgent: selfCerthttpsAgent,
    timeout: 60000,
    headers: {
      "Content-Type": "multipart/form-data",
      "API_KEY": process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD_KEY : process.env.REACT_APP_API_DEV_KEY
    },
    onUploadProgress,
  }).then((response) => {
        return response.data;
  })
  .catch((error) => {
    return error.response;
  });
 };
}