import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './components/Main.component';
import { Link } from "react-router-dom";
import './App.css';

function App() {
  return (
      <div className="App">
        <Main /> 
      </div>
  );
}
export default App;