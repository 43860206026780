import * as React from 'react';
import { useEffect,useState } from 'react';
import { useLocation, Link } from "react-router-dom";
import FaceService from '../services/face.service';
import FaceComponent from '../components/Face.Component';
import {IFace } from '../@types/Face';
import {IsFace} from '../helpers/checkIsType';
import { handleHttpError } from '../helpers/HttpErrorHandler';

const SearchComponent:React.FC = () => {

    let defaultFace: IFace = { name:'',site:'' ,gender:'' ,siteCode:'' ,thumbnailUrl:'' ,personUrl:'' ,score:'' ,lastSeen:new Date() }; 

    const location = useLocation();
    const site = location.pathname.split("/")[2];
    const name = location.pathname.split("/")[3];
    const [face,popFaceData] = React.useState<IFace>(defaultFace);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const faceService = FaceService.getInstance();

    useEffect(()=>{ 
        setLoading(true);

        faceService.getModelFaces(site,name).then(response => { 
          if(IsFace(response)){
            response.thumbnailUrl = 'images/' + name + '/' + response.thumbnailUrl;
            popFaceData(response);
            }else{
              setErrorMsg(handleHttpError(response));
          } 
        }).catch(error => {
          setErrorMsg(handleHttpError(500));
          console.log(error)
        }).then(() =>{
              setLoading(false);
        });
       }, []); // empty array means only once

    return (
  
        <div>
            <h1 style={{marginTop:'50px'}}>{name}</h1>
            <div>{loading && (                       
                  <img src={'images/spinner.gif'} alt="Loading"  width={'25px'}/>
                  )}
            </div>  
            {errorMsg && (
              <div className="alert alert-danger errorMessage" role="alert">
                {errorMsg}
              </div>
              )}
            <div className='resultsThumbsOutter' style={{marginTop:'10px'}}>
              {!errorMsg && !loading && (
                <FaceComponent key={face.thumbnailUrl}  face={face} imgWidth={'450'} imgHeight={'450'} />
                )} 
            </div>
        </div>
  );
}
export default SearchComponent;