import * as React from 'react';
import { useEffect,useState,useRef } from 'react';
import Dropzone from "react-dropzone";
import {IFace } from '../@types/Face';
import UploadService from '../services/upload.service';
import FaceComponent from '../components/Face.Component';
import {IsFace} from '../helpers/checkIsType';
import { handleHttpError } from '../helpers/HttpErrorHandler';

const Faces = () => {
    const myRef = useRef<null | HTMLDivElement>(null); 
    const [myDivPos, setmyDivPos] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [faces,popFaceData] = React.useState<IFace[]>([]);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);

    //file upload
    const MAX_SIZE = 5242880;
    const [prevfile, setPrevFile] = useState<any>(undefined);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([])
    const [currentFile, setCurrentFile] = useState<any>(undefined);
    const [progress, setProgress] = useState(0);

    const uploadService = UploadService.getInstance();

    useEffect(()=>{
      //document.title = "webcamspider.com";
      setProgress(0);
      if (myRef.current) {
        setmyDivPos(myRef.current.offsetTop);
    }
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
       return () => { if(prevfile!==undefined) URL.revokeObjectURL(prevfile.preview)};
       //setShow(true);      
  }, []); // empty array means only once

  //file upload
  const onDrop = (files:any) => {

    resetUpload();

    if (files.length > 0 && files[0].size > MAX_SIZE) {
      searchAgain();
      setErrorMsg(`file too big. Your file is ${files[0].size} KB, Max is ${MAX_SIZE} KB`);
      return;
    }

    if (files.length > 0) {
      setSelectedFiles(files);
      let currentFile = files[0];
      setCurrentFile(currentFile);
      setPrevFile(() => Object.assign(currentFile, {
        preview: URL.createObjectURL(currentFile)
      }
    ));
    }
  };

  const upload = () => {

    setLoading(true);

    uploadService.uploadFile(currentFile, (event:any) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    }).then(response => { 
      if(response.length >0 && IsFace(response[0])){
        popFaceData(response);
        }else{
          setErrorMsg(handleHttpError(response));
      } 
    }).catch(error => {
      setErrorMsg(handleHttpError(500));
      console.log(error)
    }).then(() =>{
          setLoading(false);
          setFileUploaded(true);
    });
  }

  const resetUpload = () => {
    setProgress(0);
    setErrorMsg("");
    setFileUploaded(false);
    popFaceData([]);
   }   
   
  const searchAgain = () => {
    window.scrollTo(0, myDivPos)
    resetUpload();
    setSelectedFiles([]);
    let currentFile = undefined;
    setCurrentFile(currentFile);
    //window.scrollTo(0, 0);
   }  

  function openInNewTab(url:string){
    window.open(url, "_blank", "noreferrer");
  };
 return (
  <div>
    {!fileUploaded && (
      <div ref={myRef}>
        {selectedFiles[0] ? (
            <div onClick={searchAgain} style={{maxWidth: '800px',margin:'auto',textAlign:'right'}}>
              <img src={'images/reload.png'} alt="Reload" title='Reload' width={'25px'} style={{cursor:'pointer'}}/>
          </div> 
          ):(
            <div style={{maxWidth: '800px',height:'25px',margin:'auto'}}></div>
          )}
      <Dropzone onDrop={onDrop} multiple={false} onFileDialogOpen={resetUpload} onDragOver={resetUpload}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} id="file_input"/>
              {selectedFiles[0] && selectedFiles[0].name ? (
                <div>          
                    {prevfile && (
                      <div className='dropzone-thumbsOutter'>
                        <div className='dropzone-thumbsContainer'>
                          <div className='dropzone-thumb'>
                            <div className='dropzone-thumbInner'>
                              <img
                                  src={prevfile.preview} alt='Preview'
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}         
                  <div className="selected-file">
                    {selectedFiles && selectedFiles[0].name}
                  </div>
                </div>
              ) : (
                <div className='dropzone-file-types'>
                  <div>Drop face image here to upload (.jpg, .png, .gif, .webp)</div>
                  <br/>
                  or
                  <br/><br/>
                </div>
              )}
              {currentFile ===undefined && (
                <div>
                  <div>
                    <button className="btn btn-success" onClick={onDrop}>
                          Browse for face image
                        </button>
                    </div>
                  <div className='dropzone-file-size'>
                    <div>Max face image file size: 5MB</div>
                  </div>
                </div>
              )}
            </div>
            <div style={{display: 'inline-block'}}>
              <aside className="file-selected-wrapper">
                {currentFile && (
                <button
                  className="btn btn-success"
                  disabled={loading}
                  onClick={upload}
                  id="Start_Search" 
                >
                  Start Search
                </button>
                )}
              </aside>
            </div>
            <div style={{display: 'inline-block'}}>{loading && (                       
                  <img src={'images/spinner.gif'} alt="Loading"  width={'25px'}/>
                  )}
            </div>          
          </section>
        )}
      </Dropzone>
    </div>
    )}

      {loading && (
        <div className="progress mx-auto mt-3" style={{width: "75%"}}>
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}
        <div>
        {fileUploaded && (
          <div>
            <div><br/><h3>Search results for image:</h3></div>
          </div>
        )}
        {!fileUploaded && errorMsg && (
              <div className="alert alert-danger message" role="alert">
                {errorMsg}
              </div>
         )}
        {fileUploaded && (
          <div>
                      <div className='resultsThumbsOutter'>
                        <div className='thumbsContainer'>
                          <div className='thumb'>
                            <div className='thumbInner'>
                              <img
                                  src={prevfile.preview}
                                  // Revoke data uri after image is loaded
                                  onLoad={() => { URL.revokeObjectURL(prevfile.preview) }} alt='Preview'
                                />
                            </div>
                          </div>
                        </div>
                      </div>
             {!errorMsg && faces.length===1 &&  (
              <div className="resultsMessage">
                {faces.length} possible match found:
              </div>
              )}
              {!errorMsg && faces.length>1 &&  (
              <div className="resultsMessage">
                {faces.length} possible matches found:
              </div>
              )}
             {errorMsg && (
              <div className="alert alert-danger errorMessage" role="alert">
                {errorMsg}
              </div>
              )}
            <div className='resultsThumbsOutter'>
              {!errorMsg && faces.length>0 &&  faces.map((face: IFace) => (
                <FaceComponent key={face.name}  face={face} imgWidth='200' imgHeight='200'/>
                    ))    
                } 
            </div>
          </div>
        )}
        {fileUploaded && (
            <div style={{margin:'30px'}}>
            <button
                  className="btn btn-success"
                  onClick={searchAgain}>
                   Search again
                </button>
            </div> 
            )} 
          </div> 
    </div>
  );
};

  export default Faces;