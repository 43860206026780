
export const handleHttpError = (response: any) => {
    if(response === undefined || response.status === 500){
        return "Oops, something went wrong!";
    }   
    if(response.data !== undefined && response.data.responseText !== undefined && response.data.responseText !== '' ){
        return response.data.responseText;
    }else{
        return "Oops, something went wrong!";
    }
/*     let httpError = response as IHttpError;
    if(httpError !== undefined && httpError!=null) return(httpError.title); */
     return "Oops, something went wrong!";
}